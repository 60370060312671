'use client';

import { useState } from 'react';
import { PanelList as UIPanelList } from '@swordhealth/ui-corporate';

import PanelMedia from './PanelMedia';

export default function PanelList({ panelList, ...props }) {
  const [activePanel, setActivePanel] = useState(-1);

  return (
    <UIPanelList
      {...props}
      onVisibilityChange={(index) => {
        setActivePanel(index);
      }}
      panelList={panelList.map(({ animation, media, solution, ...panel }, index) => ({
        ...panel,
        solution: solution?.slug,
        media: <PanelMedia animation={animation} media={media} selected={activePanel === index} />,
      }))}
    />
  );
}
