import { memo } from 'react';
import { ContentLinks as UIContentLinks } from '@swordhealth/ui-corporate';
import { getPostUrl } from '@/components/shared/Blog/post-types';

const ContentLinks = ({ cardList, component, defaultImage, ...props }) => {
  return (
    <UIContentLinks
      {...props}
      cardList={cardList.map(
        ({ featuredImage, title, seo, seoDescription, shortDescription, slug }) => ({
          title: title,
          description: shortDescription || seo?.description || seoDescription,
          textLink: {
            href: getPostUrl(slug, component),
            label: 'Read more',
          },
          image: featuredImage
            ? {
                url: featuredImage.url,
                alternativeText: featuredImage.alternativeText,
              }
            : {
                url: defaultImage.url,
                alternativeText: defaultImage.alternativeText,
              },
        }),
      )}
    />
  );
};

export default memo(ContentLinks);
