'use client';

import dynamic from 'next/dynamic';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useBetterMediaQuery } from '@swordhealth/ui-corporate';
import { sword } from '@swordhealth/ui-corporate/themes';

const Animation = dynamic(() => import('@/components/v2/PanelList/Animation'));
const Media = dynamic(() => import('@/components/shared/Media/Media'));

import styles from './panelList.module.css';

export default function PanelMedia({ animation, selected, media }) {
  const [animated, setAnimated] = useState(false);
  const animate = useMemo(() => animated || selected, [animated, selected]);
  const isSmallScreen = useBetterMediaQuery(`(max-width: ${sword.breakpoints.maxMd})`);

  useEffect(() => {
    if (isSmallScreen) {
      setAnimated(true);
    }
  }, [isSmallScreen]);

  return (
    <div
      className={clsx(styles.mediaContent, {
        [styles.visible]: animate,
      })}
      onTransitionEnd={() => setAnimated(true)}
    >
      {animation && <Animation animation={animation} selected={animate} />}
      {!animation && media && <Media {...media} />}
    </div>
  );
}
