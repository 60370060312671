import { memo } from 'react';
import { CardGrid, SolutionCard } from '@swordhealth/ui-corporate';

const SolutionGrid = ({ cardList, ...props }) => {
  return (
    <CardGrid {...props}>
      {cardList.map((card, index) => (
        <SolutionCard key={index} {...card} solution={card?.solution?.slug} />
      ))}
    </CardGrid>
  );
};

export default memo(SolutionGrid);
