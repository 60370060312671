import { Hero } from '@swordhealth/ui-corporate';
import dynamic from 'next/dynamic';

const HeroAnimation = dynamic(() => import('@/components/v2/HeroAnimation'));

export default function DynamicHero({ hero }) {
  if (!hero) {
    console.error("Please provide a 'hero' property to the DynamicHero");
    return null;
  }

  return (
    <>
      {hero.map(({ __component, ...item }, index) => {
        const id = `hero-${index}`;
        switch (__component) {
          case 'hero.hero-animation':
            return (
              <Hero sectionHeader={item.sectionHeader} theme={item.theme} key={id} id={id}>
                <HeroAnimation animation={item.animation} />
              </Hero>
            );
          default:
            return null;
        }
      })}
    </>
  );
}
